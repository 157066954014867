import Shuffle from "shufflejs";
import $ from "jquery";
import imagesLoaded from "imagesloaded";

const $doc = $(document);
const grid = document.getElementById("shuffle-grid");
const gridOptions = {
  itemSelector: ".js-item",
  sizer: ".js-shuffle-sizer",
  speed: 0,
  easing: "cubic-bezier(0, 0.0, 0.2, 1)",
  useTransforms: true,
};
window._shuffle = grid ? new Shuffle(grid, gridOptions) : null;
const searchInput = $(".search-form__input");

function handleFilterItems(e) {
  const url = new URL(window.location.href);
  const { group } = e.currentTarget.dataset;

  if (url.pathname == "/news-and-knowledge/") {
    e.preventDefault();
    searchInput.val("");
    filterItems(this, group);
  }
}

function handleOnReady() {
  const url = new URL(window.location.href);

  grid &&
    imagesLoaded(grid).on("progress", function (instance, image) {
      _shuffle.layout();
    });

  if (url.pathname == "/news-and-knowledge/" && url.hash.length) {
    const group = url.hash.substring(1).toLowerCase();
    const filter = $(`.js-filter[data-group=${group}]`)[0];
    filterItems(filter, group);
  }
}

function filterItems(filter, group) {
  $(".js-filter").removeClass("selected");
  $(filter).addClass("selected");

  window.history.replaceState({}, "", `#${group}`);

  const groupid = $(filter).data("catid");
  const event = new CustomEvent("beforeNewQuery", {
    detail: { groupid, group },
  });
  document.dispatchEvent(event);
}

export default function filter() {
  $doc.on("click", ".js-filter", handleFilterItems);
  $(function () {
    handleOnReady();
  });
}
