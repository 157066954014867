import $ from "jquery";
import imagesLoaded from "imagesloaded";

function debounce(func, wait) {
  let timeout;
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(func, wait);
  };
}

const _Loadmore = {
  init() {
    this.args = {};
    this.action = "loadmore";
    this._shuffle = window._shuffle;
    this.per_page = SITE.PER_PAGE;
    this.curr_page = SITE.CURR_PAGE;
    this.prev_page = SITE.CURR_PAGE;
    this.total_posts = SITE.TOTAL_POSTS;
    this.$grid = $("#shuffle-grid");
    this.$loadmore = this.$grid.find(".js-loadmore");
    this.events();
  },
  events() {
    document.addEventListener("queryUpdated", (e) => this.updateQuery(e));
    document.addEventListener("beforeNewQuery", (e) => this.beforeNewQuery(e));
    document.addEventListener("shuffleReady", (e) => this.shuffleReady(e));
    window.addEventListener(
      "scroll",
      debounce(() => {
        const { curr_page, per_page, total_posts, $grid, hasMorePosts } = this;
        const hasReachedBottom =
          $(window).scrollTop() >=
          $grid.offset().top + $grid.outerHeight() - window.innerHeight;
        const loadposts =
          hasReachedBottom && hasMorePosts(curr_page, per_page, total_posts);
        let renderPage = this.renderPage.bind(this);
        loadposts && renderPage(curr_page);
      }, 300), // Adjust the debounce time (in milliseconds) as needed
      {
        passive: true,
      }
    );
  },
  beforeNewQuery(e) {
    const { groupid, group, searchValue } = e.detail;
    this.group = group;
    this.args.category = groupid;
    this.args.search = searchValue;
    this.renderPage(1, true);
  },
  updateQuery(e) {
    $("#js-total-posts").remove();
  },
  renderPage(page, refresh) {
    let { ajaxLoadMore, $grid } = this;

    ajaxLoadMore = ajaxLoadMore.bind(this);

    page = refresh ? page : Number(page) + 1;

    this.curr_page = page;

    ajaxLoadMore($grid, page);
  },
  hasMorePosts(page, limit, total) {
    const startIndex = (page - 1) * limit + 1;
    return total === 0 || startIndex < total;
  },
  ajaxLoadMore($grid, curr_page) {
    const { AJAX_URL } = SITE;
    const { action, args, per_page, _shuffle, group } = this;
    let data = { action, per_page, curr_page };

    if (args.category) {
      data.category = args.category;
    }

    if (args.search) {
      data.search = args.search;
    }

    jQuery.ajax({
      url: AJAX_URL,
      type: "POST",
      data,
      beforeSend: function (xhr) {
        $(".loader").addClass("show");
        // clear all items from shuffle if page 1
        curr_page == 1 &&
          (group || args.search) &&
          _shuffle.remove($(".js-item"));
      },
      success: function (data) {
        const $data = $(data);

        if (!$data.length) {
          $(".loader").removeClass("show");
          return false;
        }
        $grid.append($data);

        setTimeout(function () {
          $(".loader").removeClass("show");
          _shuffle.add($data);
          $grid &&
            imagesLoaded($grid).on("progress", function (instance, image) {
              _shuffle.update();
            });
        }, 500);

        !$($data[0]).hasClass("not-found") && $grid.removeClass("is-loading");
      },
      error: function (request, status, error) {},
    });

    return false;
  },
};

export default function loadmore() {
  $(() => {
    _Loadmore.init();
  });
}
